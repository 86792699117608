
import Search from './search';
import './css/App.css';


function App() {
  return (
    <><Search/></>
  )
}

export default App;
